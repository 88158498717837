import { defineMessages } from 'react-intl';

const scope = 'app.components.Form.Input';
export const scopedId = id => `${scope}.${id}`;

export default defineMessages({
  required: {
    id: `${scope}.required`,
    defaultMessage: 'Vui lòng nhập {field}!',
  },
});
